@import "../../styles/settings/_settings";

.header {
    position: fixed;
    top: 0px;
    z-index: 8;
    transition: all ease-in-out 0.5s;
    width: 100%;

    .header_bottom {
        padding: 16px 0;
        transition: all ease-in-out 0.5s;

        .header_container {
            max-width: 1400px;
            margin: auto;

            .header_flex {
                background-color: $color-white;
                display: flex !important;
                justify-content: space-between !important;
                align-items: center !important;
                padding: 10px 12px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                .logo {
                    max-width: 200px;

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }

                .mobile_logo {
                    display: none;

                    img {
                        width: 200px;
                        height: 100%;
                    }

                    @media screen and (max-width: 767px) {
                        display: block;
                    }
                }

                .header_right {
                    display: block;
                    transition: all ease-in-out 0.5s;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .navbar {
                        margin: 0;
                        width: auto;
                        display: inline-block;
                        padding: 0px;

                        .navbar_nav {
                            display: flex;
                            align-items: center;

                            .nav_item {
                                margin: 0 10px;

                                .nav_link {
                                    padding: 0px !important;
                                    font-size: 14px;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    letter-spacing: 0.5px;
                                    line-height: 20px;
                                    color: $primary-color;
                                    transition: all ease-in-out 0.5s;
                                    position: relative;

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        transform: scaleX(0);
                                        border-radius: 5px;
                                        height: 0.05em;
                                        bottom: -5px;
                                        left: 0;
                                        background: currentcolor;
                                        transform-origin: bottom right;
                                        transition: transform 0.25s ease-out;
                                    }

                                    &:hover::after {
                                        transform: scaleX(1);
                                        transform-origin: bottom left;
                                    }

                                    @media (min-width:768px) and (max-width:1024px) {
                                        font-size: 12px;
                                    }
                                }

                                // .nav_btn {

                                // }
                            }

                            .dropdown {
                                position: relative;

                                .nav_link {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;

                                    .dropdown_icon {
                                        margin-left: 5px;
                                        transition: transform 0.3s ease;
                                    }
                                }

                                &:hover .dropdown_icon {
                                    transform: rotate(180deg);
                                }

                                &:hover .dropdown_menu {
                                    display: block;
                                }

                                .dropdown_menu {
                                    display: none;
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    background: white;
                                    padding: 10px 0;
                                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                                    z-index: 1000;
                                    min-width: 120px;

                                    li {
                                        margin-bottom: 5px;
                                        padding: 0 10px;

                                        .drop_down_nav_link {
                                            color: $primary-color;
                                            transition: color 0.3s ease;
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }

                        }
                    }

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }

                .header_right_btn {
                    display: flex;
                    align-items: center;

                    .call_icon {
                        margin-right: 20px;
                        color: $primary-color;
                        display: flex;
                        font-family: "Poppins", sans-serif;
                        color: $primary-color;
                        border: 1px solid $primary-color;
                        background: transparent;
                        transition: 0.5s;
                        text-align: center;
                        font-size: 14px;
                        height: 46px;
                        padding: 10px 20px;
                        align-items: center;

                        .call_react_icon {
                            height: 20px;
                            width: 20px;
                        }

                        &:hover {
                            background: $primary-color;
                            color: $color-white;

                            p {
                                color: $color-white;
                            }
                        }

                        p {
                            margin-bottom: 0;
                            margin-left: 5px;
                            font-size: 14px;
                        }

                        // @media only screen and (min-width:768px) and (max-width:1024px) {
                        //     display: none;
                        //   }
                    }

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }

                .header_toggle {
                    display: none;

                    @media screen and (max-width: 767px) {
                        cursor: pointer;
                        transition: all ease-in-out 0.3s;
                        border: none;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        width: 30px;
                        padding: 0;
                    }

                }
            }
        }

        @media screen and (max-width: 767px) {
            padding-top: 0;
        }
    }
}

.header_trans {
    .mobile_header {
        background-color: $color-white;
        height: 100vh;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 10;
        display: inline;

        .mobile_header_div {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            align-items: center;

            .navbar_cut_btn {
                font-size: 22px;
                border: none;
                outline: none !important;
                background-color: transparent;
                height: 30px;
                width: 30px;
                padding: 0px;
                line-height: 10px;
                overflow: hidden;
                z-index: 9999;
                display: block;
            }


        }

        .res_header_links {
            ul {
                padding: 0;

                li {
                    a {
                        color: $primary-color;
                        margin-bottom: 10px;
                        font-size: 16px;
                        text-transform: uppercase;

                    }
                }
            }
        }

        .header_right_btn {
            display: flex;

            .call_icon {
                display: flex;
                padding: 10px 0;
                align-items: center;
                font-family: "Poppins", sans-serif;
                color: $primary-color;
                border: 1px solid $primary-color;
                background: transparent;
                transition: 0.5s;
                text-align: center;
                font-size: 14px;
                height: 46px;
                padding: 10px 20px;
                align-items: center;
                max-width: 122px;
                margin-left: 10px;

                p {
                    margin-bottom: 0;
                    font-size: 16px !important;
                    margin-left: 5px;
                }
            }

        }

        .contact_social_links {
            display: flex;
            column-gap: 10px;
            padding-top: 26px;

            .icon {
                height: 42px;
                width: 42px;
                border: 1px solid $contact-icon-color;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    color: $contact-icon-color;
                    font-size: 16px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    background-color: $primary-color;
                    transition: 0.5s;
                    border: 1px solid transparent;

                    a {
                        color: $color-white;
                    }
                }
            }
        }
    }

}