@import "../../styles/settings/_settings";

@mixin same-size($sz) {
    width: $sz;
    height: $sz;
}

.loader_main {
    background: black;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    z-index: 9999;
    inset: 0;

    .loader {
        --sz: 7rem;
        width: calc(var(--sz) * 2);
        height: var(--sz);
        // margin: auto;
        display: flex;
        justify-content: space-evenly;

        .loader__sq {
            --p-sz: calc(var(--sz) / 4);
            --m-offset: calc((var(--p-sz) * -1) * 0.75);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            height: 100%;
            width: 50%;

            &::before,
            &::after {
                @include same-size(var(--p-sz));
                content: "";
                position: relative;
                // transform is what's being animated
                transform: translateY(calc((var(--sz) - var(--p-sz)) * -1));

                animation: loader-box-bounce 0.5s cubic-bezier(0.3, 0.5, 0.4, 0.95) calc(var(--i) * 0.06s) infinite alternate-reverse;
            }

            &:first-of-type {
                margin-right: var(--m-offset);

                // first el animated and first visual element
                &::before {
                    --i: 1;
                    border: 3px solid #ff7ab5;
                    border-radius: 50%;
                }

                // second element visually; third el animated
                &::after {
                    --i: 3;
                    background-color: #c643fb;

                    // make the loader pieces triangles in supporting browsers
                    @supports (clip-path: circle()) {
                        clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
                    }
                }
            }

            &:nth-of-type(2) {

                // third element visually; 2nd el animated
                &::before {
                    --i: 2;
                    border: 3px solid #ffb650;
                }

                // fourth element visually, fourth el animated
                &::after {
                    --i: 4;
                    background-color: #2fe2dd;
                    border-radius: 50%;

                    @supports (clip-path: circle()) {
                        border-radius: 0;
                        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    }
                }

            }
        }
    }

    .loading_text {
        color: white;
        font-family: 'Cavier-Bold';
        font-weight: 200;
        font-size: 40px;
        left: 0;
        right: 0;
        width: 180px;

        &:after {
            content: "Loading";
            animation: loading-text 3s infinite;

        }

        @media screen and (max-width: 767px) {
            font-size: 24px;
            width: 100px;
        }
    }
}

.new_loader_main {
    background: rgb(0 0 0 / 50%);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    z-index: 9999;
    inset: 0;

    .newloader {
        width: 50px;
        aspect-ratio: 1;
        display: grid;
        -webkit-mask: conic-gradient(from 15deg, #0000, #000);
        animation: l26 1s infinite steps(12);

        &,
        &:before,
        &:after {
            background:
                radial-gradient(closest-side at 50% 12.5%,
                    #fff 96%, #0000) 50% 0/20% 80% repeat-y,
                radial-gradient(closest-side at 12.5% 50%,
                    #fff 96%, #0000) 0 50%/80% 20% repeat-x;
        }

        &:before,
        &:after {
            content: "";
            grid-area: 1/1;
            transform: rotate(30deg);
        }

        &:after {
            transform: rotate(60deg);
        }
    }

    @keyframes l26 {
        100% {
            transform: rotate(1turn);
        }
    }
}

@keyframes loader-box-bounce {
    to {
        transform: translateY(0);
    }
}

.new_loader_small_main {
    // background: rgb(0 0 0 / 50%);
    // height: 80vh;
    // width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 40px;
    // position: fixed;
    // z-index: 9999;
    // inset: 0;

    .newloader {
        width: 50px;
        aspect-ratio: 1;
        display: grid;
        -webkit-mask: conic-gradient(from 15deg, #0000, #000);
        animation: l26 1s infinite steps(12);

        &,
        &:before,
        &:after {
            background:
            radial-gradient(closest-side at 50% 12.5%,
             #1A1A1A 96%,#0000) 50% 0/20% 80% repeat-y,
            radial-gradient(closest-side at 12.5% 50%,
             #1A1A1A 96%,#0000) 0 50%/80% 20% repeat-x;
        }

        &:before,
        &:after {
            content: "";
            grid-area: 1/1;
            transform: rotate(30deg);
        }

        &:after {
            transform: rotate(60deg);
        }
    }

    @keyframes l26 {
        100% {
            transform: rotate(1turn);
        }
    }
}

@keyframes loading-text {
    0% {
        content: "Loading"
    }

    25% {
        content: "Loading."
    }

    50% {
        content: "Loading.."
    }

    75% {
        content: "Loading..."
    }
}


