@import "../../styles/settings/_settings";

.footer_sec {
    background-color: #1a1a1a;
    border-top: 1px solid $color-dark-gray;
    margin-top: -12px;

    .footer_upper {
        padding: 40px 0;

        @media screen and (max-width: 767px) {
            padding-top: 40px;
            padding-bottom: 0;

            .footer_tripAdv  {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .footer_column {
            p {
                color: $color-white;
            }

            ul {
                padding: 0 !important;

                li {
                    padding-bottom: 16px;
                    display: flex;
                    align-items: center;

                    span {
                        padding-right: 14px;

                    }

                    a {
                        color: $text-color;
                        font-size: 14px;
                        text-align: left;
                        font-family: 'Poppins', sans-serif;
                        display: inline;

                        p {
                            color: $text-color;
                            font-size: 14px;
                            display: inline;
                        }

                        @media screen and (max-width: 767px) {
                            text-align: center;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        padding-bottom: 10px;
                    }

                    &:hover {
                        a {
                            color: $color-white;
                        }
                    }
                }

                .footer_social {
                    display: flex !important;
                    flex-direction: column !important;
                    padding-top: 10px;


                    .footer_social_links {
                        display: flex;
                        column-gap: 10px;
                        padding-top: 26px;

                        .icon {
                            border: 1px solid $border-color;
                            height: 40px;
                            width: 40px;
                            display: flex;
                            justify-content: center;
                            border-radius: 50%;
                            transition: 0.5s;


                            a {
                                font-size: 16px;
                                color: #FFFFFF;
                                cursor: pointer;
                                display: flex;
                                align-items: center;

                            }
                        }
                    }
                }

                @media screen and (max-width: 767px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

            .icon:hover {
                background-color: $color-white;

                a {
                    color: $primary-color !important;
                }
            }

            .contect_info {
                display: flex;
                align-items: flex-start;
                padding-bottom: 16px;
                column-gap: 10px;

                .contact_text {

                    a {
                        font-size: 14px;
                        // color: aqua;
                    }
                }

                div {
                    a {
                        color: $text-color;
                        padding-right: 16px;
                        font-size: 14px;
                    }
                }

                &:hover {
                    a {
                        color: $text-color;
                    }
                }

                // .footer_icon {
                //     width: 20%;
                // }
            }

            @media screen and (max-width: 767px) {
                flex: 100% 1;
                margin-bottom: 20px;
            }
        }

     
    }

    .footer_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            padding: 20px 0;
        }

        .footer_bottom_left {
            p {
                color: $text-color;
                font-size: 14px !important;
                margin: 0;

            }

            @media screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
        }

        .footer_bottom_right {
            p {
                color: $text-color;
                margin: 0;


                .footer_p {
                    padding-right: 10px;
                    font-size: 14px;
                }
            }
        }


    }
}