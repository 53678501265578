@import "./settings/_settings";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  // font-family: 'HelveticaNowText-Regular' -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

/* Common Css */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0px 0 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  position: relative;
  text-rendering: optimizeLegibility;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#112019+0,303d37+50,606a65+100 */
}

.container {
  max-width: 1300px !important;
  // padding: 0 2rem;
}

::-moz-selection {
  background: $primary-color;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: $primary-color;
  color: #fff;
  text-shadow: none;
}

a {
  font-family: 'Poppins', sans-serif;
  outline: 0 !important;
  cursor: pointer;
  text-decoration: none !important;
  display: inline-block;
}

p {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  margin: 0;
  color: $ternary-color;
  padding: 0;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.no_padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no_margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

ul {
  padding: 0;
  margin: 0px !important;
}

ul li {
  list-style-type: none;
}

section {
  display: inline-block;
  width: 100%;
  position: relative;
}

.w-100 {
  display: inline-block;
  width: 100%;
}

.clearfix {
  display: block;
  width: 100%;
}

.main_color {
  color: $primary-color;
}

.common_padding {
  padding: 60px 0px;
}

.common_title h1 {
  font-family: 'Cavier-Bold';
  text-transform: capitalize;
  font-size: 64px;
}

.common_title h2 {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
  color: $color-gray;
  padding: 10px 0;

  @media screen and (max-width: 767px) {
    font-size: 24px !important;
  }
}


.common_title h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: $color-gray;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.common_title h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: $primary-color;
  font-weight: 400;
  text-transform: uppercase;
}

.common_title span {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: $primary-color;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;

  // &::before {
  //   position: absolute;
  //   content: '';
  //   right: -35px;
  //   top: 10px;
  //   width: 31px;
  //   height: 3px;
  //   background: $primary-color;
  //   border-radius: 10px;
  // }

  @media screen and (max-width: 767px) {
    text-align: center;
  }

}

.signature_section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.email_button {
  color: $primary-color;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 20px;
  transition: 1s;

  &:hover {
    color: $primary-color;
    // border-bottom: 1px solid $primary-color;
    transition: 1s;
  }
}

.email_para {
  max-width: 1300px;
  margin-left: 10px;
  padding-top: 20px;
  margin-bottom: 0;

}

.common_title span {
  color: $primary-color;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
}

.common_btn {
  font-family: "Poppins", sans-serif;
  color: $color-white;
  background: $primary-color;
  text-transform: uppercase;
  // font-weight: 600;
  transition: 0.5s;
  text-align: center;
  font-size: 14px;
  height: 46px;
  padding: 10px 40px;
  border: 1px solid $primary-color;

  &:hover {
    background: transparent;
    color: $primary-color;
    // font-weight: 600;
  }
}

.secound_common_btn {
  font-family: "Poppins", sans-serif;
  color: $primary-color;
  border: 1px solid $primary-color;
  background: transparent;
  transition: 0.5s;
  text-align: center;
  font-size: 14px;
  height: 46px;
  padding: 10px 40px;

  &:hover {
    background: $primary-color;
    color: $color-white;
  }

  @media screen and (max-width: 767px) {
    padding: 7px 14px !important;
  }
}

.filtered_btn {
  background: $primary-color;
  color: $color-white;
  border: 1px solid $primary-color;
  // margin: 0 5px;
}

.banner_btn {
  background: $color-white;
  border: 1px solid $color-white;
  color: $color-black;

  &:hover {
    border: 1px solid $color-white;
    color: $color-white;
  }
}

.banner_btn2 {
  background: transparent;
  border: 1px solid $color-white;
  color: $color-white;

  &:hover {
    background: $color-white;
    color: $primary-color;
  }
}

.icon_text {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding-left: 20px;

    @media screen and (max-width: 767px) {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-bottom: 20px;
  }
}

.form_message {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  opacity: 1;
  padding: 10px;
}

.success {
  color: rgba(16, 185, 129);
}

.error {
  color: rgba(239, 68, 68);
}

.first-order-mobile {
  @media screen and (max-width: 767px) {
    order: -1;
  }

}

.icon_con {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid $border-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black_icon_con {
  border: 1px solid $primary-color;
}

.common_title .common_btn {
  margin-top: 20px;
}

/* Header Css */
.flex-center {
  display: flex;
  align-items: center;
}

.insta_gallery_section {
  .insta_username {
    a {
      color: $color-gray;
    }
  }

  .insta_gallery_slider {
    padding-top: 40px;
    height: 100%;

    .slick-slide {
      div {
        .instaimg {
          height: 400px;
          position: relative;
          width: 100%;
          cursor: pointer;

          img {
            height: 100%;
            width: 100%;
            transition: .5s ease;
            display: block;
            object-fit: cover;
          }

          .middle {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background-color: #000000bf;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 30px;
              height: 30px;
            }
          }

          @media screen and (max-width: 480px) {
            height: 400px;

            img {
              height: 100%;
              object-fit: cover;
            }
          }

          @media only screen and (min-width:480px) and (max-width:600px) {
            height: 300px;

            img {
              height: 100%;
              object-fit: cover;
            }
          }

          @media only screen and (min-width:600px) and (max-width:1024px) {
            height: 278px;

            img {
              height: 100%;
              object-fit: cover;
            }
          }

          &:hover {
            .middle {
              opacity: 1;
            }
          }
        }
      }
    }

    a {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}

.similar_room_slide .slick-list .slick-track .slick-slide div {
  margin: 10px;

  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.header.header_fixed .header_bottom {
  padding: 16px 0;
}

.logo .navbar-brand {
  padding: 0px;
}

.logo img {
  max-width: 250px;
  transform: scale3d(1, 1, 1);
  transition: all ease-in-out 0.5s;
}

.header .navbar .navbar-nav {
  flex-direction: inherit;
}

.header .navbar {
  margin: 0;
  width: auto;
  display: inline-block;
  padding: 0px;
}

/* Banner Css */
.banner {
  height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #00000054;
}

.banner_text {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner_des {
  color: white;
}

.banner_title {
  color: white;
  font-size: 80px;
  margin-bottom: 10px !important;
}

.arrow_down_btn {
  position: relative;
  z-index: 99;
  float: right;
  bottom: 80px;
  background-color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  outline: none;
  color: black;
  border: none;
}


.bi-arrow-right-short {
  font-size: 20px;
  line-height: 10px;
  position: relative;
  top: 2px;
}

.common_btn i.bi.bi-arrow-right-short {
  font-size: 20px;
  line-height: 10px;
  position: relative;
  top: 2px;
}

.common_btn span {
  margin-left: 10px !important;
}


.common_title .common_btn {
  margin-top: 20px;
}

.single_room_slider {
  .slick-slide {
    div {
      margin: 0 10px;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        margin: 0 5px;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transition: all .5s ease-in-out;
    align-items: center;
    z-index: 7;
    background-color: transparent;
    color: black;
  }

  .slick-prev {
    left: 12px;
    // left: 10%;

    &::before {
      background-image: url("/icons/gal-arrowleft.svg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1 !important;

    }

    @media screen and (max-width: 767px) {
      left: 12px;
    }
  }

  .slick-next {
    right: 12px;

    &::before {
      background-image: url("/icons/gal-arrowright.svg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1;
    }

    @media screen and (max-width: 767px) {
      right: 12px;
    }
  }
}

.gallery_slider {
  .slick-list {
    .slick-track {
      display: flex;
      align-items: center;

      .slick-slide {
        div {
          margin: 0 20px;
          overflow: hidden;

          @media screen and (max-width: 767px) {
            margin: 0 5px;
          }

          @media only screen and (min-width:768px) and (max-width:1024px) {
            margin: 0 10px;
          }
        }
      }

      .slick-center {
        height: 500px;
        z-index: 1;

        div {
          height: 100%;

          div {
            img {
              height: 100%;
            }
          }
        }

        @media screen and (max-width: 767px) {
          height: 200px;
        }

        @media only screen and (min-width:768px) and (max-width:1024px) {
          height: 300px;
        }

        @media only screen and (min-width:1025px) and (max-width:1300px) {
          height: 400px;
        }
      }
    }
  }

  .slick-dots {
    display: none !important;
  }

  .slick-arrow {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transition: all .5s ease-in-out;
    align-items: center;
    z-index: 9;
    background-color: transparent;
    color: black;
  }

  .slick-prev {
    // left: 12px;
    left: 10%;

    &::before {
      background-image: url("/icons/gal-arrowleft.svg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1 !important;

    }

    @media screen and (max-width: 767px) {
      left: 5%;
    }
  }

  .slick-next {
    right: 10%;

    &::before {
      background-image: url("/icons/gal-arrowright.svg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1;
    }

    @media screen and (max-width: 767px) {
      right: 5%;

    }
  }
}


// .gallery_slider .slick-list .slick-track .slick-slide {
//   margin: 0 10px;

//   @media screen and (max-width: 767px) {
//     margin: 0 10px;
//   }
// }

// .gallery_slider .slick-list {
//   margin: 0 -30px;
// }

// .gallery_slider {
//   .slick-list {
//     padding: 50px 0 !important;

//     .slick-track {
//       display: flex;
//       align-items: center;

//       .slick-center {
//         transform: scale(1.1);
//         // height: 500px;
//         z-index: 1;

//         // div {
//         //   height: 100%;

//         //   div {
//         //     img {
//         //       height: 100%;
//         //     }
//         //   }
//         // }
//       }
//     }
//   }

//   .slick-dots {
//     display: none !important;
//   }

//   .slick-arrow {
//     position: absolute;
//     height: 30px;
//     width: 30px;
//     border-radius: 50%;
//     display: flex;
//     justify-content: center;
//     transition: all .5s ease-in-out;
//     align-items: center;
//     z-index: 9;
//     background-color: transparent;
//     color: black;
//   }

//   .slick-prev {
//     // left: 12px;
//     left: 10%;

//     &::before {
//       background-image: url("/icons/gal-arrowleft.svg");
//       background-size: cover;
//       background-repeat: no-repeat;
//       opacity: 1 !important;

//     }

//     @media screen and (max-width: 767px) {
//       left: 5%;
//     }
//   }

//   .slick-next {
//     right: 10%;

//     &::before {
//       background-image: url("/icons/gal-arrowright.svg");
//       background-size: cover;
//       background-repeat: no-repeat;
//       opacity: 1;
//     }

//     @media screen and (max-width: 767px) {
//       right: 5%;

//     }
//   }
// }

.rooms_slider {
  .slick-dots {
    bottom: 0px;

    li {
      margin: 0;

      button {
        background-color: $color-light-gray;
        height: 10px;
        width: 10px;

        &::before {
          display: none;
        }
      }
    }

    .slick-active {
      button {
        background-color: $primary-color;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transition: all .5s ease-in-out;
    align-items: center;
    z-index: 9;
    background-color: transparent;
    color: black;
  }

  .slick-prev {
    // left: 12px;
    // left: 10%;

    &::before {
      background-image: url("/icons/gal-arrowleft.svg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1 !important;

    }

    @media screen and (max-width: 767px) {
      left: -10px;
    }

    @media only screen and (min-width:768px) and (max-width:1024px) {
      left: 0;
    }
  }

  .slick-next {
    // right: 12px;
    // right: 10%;

    &::before {
      background-image: url("/icons/gal-arrowright.svg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1;
    }

    @media screen and (max-width: 767px) {
      right: -10px;
    }

    @media only screen and (min-width:768px) and (max-width:1024px) {
      right: 0;
    }
  }
}

.testi_slider {
  .slick-dots {
    li {
      margin: 0 3px;
      // top: 7px;
      // right: 7px;

      button {
        background-color: $color-white;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        padding: 3px;
        top: 6px;
        position: absolute;
        right: 6px;

        &::before {
          display: none;
        }
      }
    }

    .slick-active {
      border: 1px solid $color-white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      // top: 4px;
    }
  }
}


.hide,
.split-line {
  overflow: hidden;
}


.revealUp {
  opacity: 0;
  visibility: hidden;
}

// #fb-root > div.fb_dialog.fb_dialog_advanced.fb_shrink_active {
//   right: initial !important;
//   left: 18pt;
//   z-index: 9999999 !important;
// }
// .fb-customerchat.fb_invisible_flow.fb_iframe_widget iframe {
//   right: initial !important;
//   left: 18pt !important;
//   position: fixed;
// }

#smooth-content,
#smooth-wrapper {
  will-change: transform;
}

.banner_slider {
  margin: 0 auto;

  .slick-dots {
    bottom: 20px;

    li {
      width: 40px;
      height: 6px;
      margin: 0;
      background: $ternary-color;

      button {
        color: white;
        // background: $ternary-color;
        height: 6px;
        width: 40px;
        padding: 2px;

        &::before {
          display: none;
        }
      }

      &:first-child {
        border-right: 5px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .slick-active {
      color: white;
      background: $color-white;
    }
  }
}

.activity_slider {
  .slick-dots {
    li {
      margin: 0;

      button {
        background-color: $color-light-gray;
        height: 10px;
        width: 10px;

        &::before {
          display: none;
        }
      }
    }

    .slick-active {
      button {
        background-color: $primary-color;
      }
    }
  }
}

.gap_between {
  padding: 30px;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  z-index: 9;

  .modal_image {
    position: relative;
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 767px) {
      max-width: 80%;
    }
  }

  .modal_video {
    position: relative;
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 767px) {
      max-width: 80%;
    }
  }

  .close_icon {

    // position: absolute;
    // top: 20px;
    // right: 20px;
    // font-size: 30px;
    // color: #ffffff;
    // z-index: 999;
    // cursor: pointer;
    img {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #ffffff;
      z-index: 999;
      cursor: pointer;
    }

  }

  .overlay-arrows_left {
    display: flex;
    // background-color: #6d6d6d;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;

    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }

  .overlay-arrows_right {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;

    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }

  .common_padding {
    padding: 40px 0px;
  }

  .common_title h1 {
    font-size: 40px;
    line-height: 1.3;
    margin: 0;
  }

  .common_title h2 {
    font-size: 30px;
    line-height: 1.4;
    text-align: center;
  }

  .common_title h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px !important;
    line-height: 22px;
    // text-align: center;
  }

  .common_btn {
    font-size: 14px !important;
    font-weight: 400;
  }

  .bi-arrow-right-short {
    font-size: 18px;
    line-height: 10px;
    position: relative;
    top: 2px;
  }

  .first_order {
    order: -1;
  }

  .split-line {
    text-align: center !important;
  }

  .text_center_mob {
    text-align: center;
  }

  .long_title_ani {
    font-size: 35px;
  }

  .team_slider .slick-list .slick-track .slick-slide div {
    margin: 40px 30px 0 30px;
  }
}

.btn-whatsapp-pulse {
  background: #25d366;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 8;

  @media screen and (max-width: 767px) {
    width: 50px;
    height: 50px;

    svg {
      width: 25px !important;
      height: 25px !important;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }

  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.tripadvisor-widget {
  height: 150px;

  .widCOEImg {
    width: 150px !important;
    height: 150px !important;
    object-fit: contain;
  }
}


.btn-whatsapp-pulse-border {
  bottom: 120px;
  right: 20px;
  animation-play-state: paused;
}

.date_range_child {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .daterange_input {
    height: 50px;
    border: 1px solid #848FAC;
    border-radius: 10px;
    padding: 8px 12px;

    &:focus {
      outline: none;
    }
  }
}

.date_range {
  position: absolute;
  top: 70px;
  z-index: 2;
}

.headerSearchText {
  color: #222222;
  font-size: 14px;

  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 14px;
  }
}

.search_form_date_con {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      @media screen and (max-width: 767px) {
        input {
          font-size: 12px;
        }
      }
    }
  }
}

.react-datepicker__input-container input {
  background-color: white;
  color: #333;
  border: 0;
  outline: none;
  width: 100%;

  @media screen and (max-width: 767px) {
    font-size: 13px !important;

  }
}

.react-datepicker__month-container.react-datepicker__month.react-datepicker__week.react-datepicker__day--in-range {
  background-color: black !important;
}

.options {
  position: absolute;
  top: 34px;
  background-color: white;
  color: gray;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 767px) {
    // bottom: -30px;
  }
}

.optionItem {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.optionCounterNumber {
  width: 31px;
  text-align: center;
  color: black;
  background-color: transparent;
  border: none;
}

.childAge_input {
  border: 1px solid #858484;
  height: 23px;
  background-color: #fff;
  color: #000000;
  width: 32px;
  -moz-appearance: textfield;
  text-align: center;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}

.optionCounter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: black;
}

.optionCounterButton {
  border: none;
  color: black;
  background-color: transparent;
  // width: 30px;
  // height: 30px;
  // border: none;
  // color: white;
  // background: #8B9556;
  // cursor: pointer;
  // background-color: white;
}

.optionCounterButton:disabled {
  cursor: not-allowed;
}

.default_text {
  @media screen and (max-width: 767px) {
    text-align: left;
  }
}

.d_flex {
  display: flex;
}

.content_space_between {
  display: flex;
  justify-content: space-between;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.booking_engine_rooms_slider {
  width: 100%;
  height: 100%;
}

.flex_center {
  align-items: center;
}

.lightbox_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(27 27 27 / 53%);
  display: flex;
  align-items: center;
  z-index: 999999;

  .modal_image {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 767px) {
      max-width: 80%;
    }
  }

  .close_icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }

  .overlay-arrows_left {
    display: flex;
    // background-color: #6d6d6d;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;

    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }

  .overlay-arrows_right {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;

    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
}

.equal_width {
  width: 50%;

  @media screen and (max-width: 767px) {
    width: 48%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 20px !important;
  font-size: 20px !important;
}

// p {
//   text-align: left;
// }

.home_search_result_banner {
  position: absolute;
  top: 178px;
  z-index: 9;
  left: 0;
  right: 0;
  margin: auto;
}

.reservation_back_btn {
  margin-top: 130px;

  @media screen and (max-width: 767px) {
    margin-top: 0px;

  }
}

#rooms_fetchered_image_slider {

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 30px !important;
  }
}

#price_popup_container {
  position: fixed;
  transition: transform 0.9s, top 0.4s;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 99;
  height: 100vh;

  #price_popup_content {
    padding: 15px 25px 30px;
    width: 350px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    /* top: 30%; */
    z-index: 999999;
    /* left: 37%; */
    text-align: center;

    .popup_image {
      position: relative;
      width: 80px;
      height: 80px;
    }

  }
}

.search_form {
  position: absolute;
  bottom: -40px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9;
  height: fit-content;

  @media screen and (max-width: 767px) {
    top: 100%;
    height: 190px;
    padding: 0 10px;
  z-index: 7;

  }
}

.banner_slider .slick-dots {
  bottom: 20px;
}

.disabled_button_booking_flow {
  position: relative;
  padding: 10px 20px;
  background-color: #ccc;
  color: #666;
  border: none;
  cursor: not-allowed;
}

.disabled_btn_tooltip {
  visibility: hidden;
  width: 157px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  right: 0;
  opacity: 1;
  transition: opacity 0.3s;
}

.disabled_button_booking_flow:hover .disabled_btn_tooltip {
  visibility: visible;
  opacity: 1;
}

.banner_slider {
  position: relative;
}

.error_end {
  margin: 200px 0 100px 0;
  width: 500px;
}

.animate_btn {
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
}

.animate_btn:hover {
  color: #000000;
  background: transparent;
  box-shadow: none;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
}

.animate_btn:before,
.animate_btn:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 1.5px;
  width: 0;
  background: #000000;
  box-shadow:
    -1px -1px 5px 0px #fff,
    7px 7px 20px 0px #0003,
    4px 4px 5px 0px #0002;
  transition: 400ms ease all;
}

.animate_btn:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.animate_btn:hover:before,
.animate_btn:hover:after {
  width: 100%;
  transition: 500ms ease all;

}

.animate_btn {
  height: 40px;
  color: #fff;
  width: auto !important;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  line-height: 20px;
  background: #000000;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

@media screen and (max-width: 767px) {
  .error_end {
    width: 300px;
    margin-top: 150px;
  }
}

.thankyou_main_con {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thankyou_main {
  h3 {
    font-size: 20px;
    margin-bottom: 10px !important;
  }

  p {
    font-size: 12px;
    line-height: 18px;
  }

  .react-datepicker-wrapper input {
    height: 10px !important;
  }

  .search_form input {
    font-size: 12px;
  }


  .hotel_location_icon {
    width: 25px;
    height: 20px;
    padding-right: 5px;
  }
}

.date_input_form {
  font-size: 14px;
  font-family: 'Lato', sans-serif;

  @media screen and (max-width: 767px) {
    font-size: 14px !important;
  }
}

.secound_common_btn {
  height: fit-content;
}

.react-datepicker-wrapper {
  z-index: 100 !important;

  .react-datepicker__input-container {
    input {
      // text-align: center;
      border-radius: 5px;
      padding: 0;
      // height: 45px !important;
      border: none;
      // font-size: 16px;
      color: #222222;
      background: #ffffff00;
      width: 100%;
      // min-width: 248px;

      &:focus {
        outline: none;
      }
    }
  }
}

.date-picker {
  margin: 0 2px;
  overflow: hidden;

  .react-datepicker__input-container {
    input {
      text-align: center;
      border-radius: 10px;
      height: 50px !important;
      border: 1px solid #848FAC !important;
      // color: white;
      background: #ffffff00;

      &:focus {
        outline: none;
      }
    }

    &::before {
      background-color: white !important;
    }
  }
}

.date-picker-reports {
  z-index: 9999 !important;
}

.react-datepicker {
  border: none !important;
  z-index: 9999 !important;
  box-shadow: 0 1px 2px 0 #acacac, 0 4px 10px 0 #acacac;
  // padding: 0 10px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background: #1A1A1A !important;
  border: 2px solid #1A1A1A !important;
  color: white !important;

}


.react-datepicker__day {
  padding: 0 5px;
  width: 2rem !important;

}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #1A1A1A !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #303715;
}

.react-datepicker__day-name {
  color: #1A1A1A !important;
  width: 2rem !important;
}

.react-datepicker__day-names {
  padding: 5px 0;
}

.dining-radio-input {
  display: flex;
  margin-bottom: 20px;

  span {
    font-size: 18px;
  }

  label {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    span {
      font-size: 16px;
    }
  }
}

.dining-radio-input input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  margin: 0 10px !important;
  color: white !important;
  font-size: 15px !important;
  padding: 0 !important;
  width: 20px !important;
  height: 20px !important;
  // border-radius: 50% !important;
  border: 2px solid #888888 !important;
  background: transparent !important;
  outline: none !important;
  cursor: pointer !important;
  position: relative !important;
}

.dining-radio-input input[type='radio']:checked::before {
  content: "\2714" !important;
  font-family: sans-serif;
  color: white !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 20px !important;
  height: 20px !important;
  // border-radius: 50% !important;
  border: 2px solid #222222 !important;
  background: #222222 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.dining-radio-input label {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


// Accordion
.accordion {
  overflow: hidden;
  margin: 10px 0;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #222222;
  border-radius: 5px;
}

.accordion__title {
  background-color: transparent !important;
  border: 0 !important;
  width: 100% !important;
  text-align: left;
  display: flex;
  outline: none;
  transition: 0.3s ease;
  cursor: pointer;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;

  h4 {
    width: 95%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin: 0;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .accordion_btn {
    width: 5%;
    height: auto;
    display: flex;
    justify-content: flex-end;

    .arrow_btn {
      border: none;
      background: none;
      cursor: pointer;
      font-size: 1.2rem;
    }

    .rotate {
      transition: transform 0.5s ease;
      transform: rotate(180deg);
    }

    @media (max-width: 768px) {
      width: 10%;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
    width: 90%;
  }
}

.accordion__content {
  overflow: hidden;
  transition: max-height 0.3s ease;

  p {
    padding: 0 20px 0 20px;
    margin-top: 0;

  }

  @media (max-width: 768px) {
    p {
      font-size: 14px;
      padding: 10px;
      text-align: start;
    }
  }
}

.common_btn_white_bg {
  border: 1px solid #fff;
  background: #fff;
  padding: 10px 40px;
  color: #333;
}